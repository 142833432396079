<template>
  <div class="sub-header-thin-div">
    <v-card :class="`common-content-div ${isMobile ? '' : 'w-75 mx-auto my-25'}`">
      <v-card-text class="center">
        <p :class="`font-bold color-white ${isMobile ? 'font-30 mt-10' : 'font-40 mt-20'}`">EVENTBOX {{ $t('support') }}</p>
      </v-card-text>
      <v-card-text class="center">
        <p :class="`color-white ${isMobile ? 'my-10 font-18' : 'my-30 font-30'}`">{{ $t('support_description')}}</p>
      </v-card-text>
    </v-card>
    <v-card :class="`box-white ${isMobile ? 'mx-5 my-10 p-5' : 'w-75 mx-auto my-20 p-20'}`">
      <div class="d-flex flex-column">
        <span class="font-12">Question</span>
        <textarea class="w-100 input-normal" v-model="question" rows="5" maxlength="500" />
        <span class="mt-5 font-12">{{ $t('your_email') }}</span>
        <input class="w-100 input-normal" v-model="email" />
        <VueRecaptcha
          class="mt-5 mx-auto"
          :sitekey="sitekey"
          @verify="onCaptchaVerified"
          @expired="onCaptchaExpired"
          @error="onCaptchaError"
          />
        <v-btn class="my-5 mx-auto button-normal" @click="onSubmit()" :disabled="!captchaVerified || !email || !question">
          <span class="px-10">{{ $t('submit') }}</span>
        </v-btn>
      </div>
    </v-card>
  </div>
</template>

<script>
import { VueRecaptcha } from 'vue-recaptcha';
import { httpsCallable } from 'firebase/functions';

import { functions } from '../../../main';
import { showLoading } from '../../../functions';

export default {
  name: 'ContactUs',
  components: {
    VueRecaptcha
  },
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    }
  },
  data() {
    return {
      sitekey: '6LdQ0gokAAAAAOAMjcU-uTYNhXSTCjJMVl6Sg4pF',
      //secretKey: '6LdQ0gokAAAAABbhIaoCcekkI_bw1OmlMfOAkRKm',
      captchaVerified: false,
      valid: false,
      question: '',
      email: ''
    };
  },
  methods: {
    onSubmit() {
      const loader = showLoading(this, 1);
      const func = httpsCallable(functions, 'sendVerificationCode');
			const params = {
        isCustom: true,
        from: this.email,
        question: this.question.replaceAll('\n', '<br/>'),
        to: 'info@eventboxde.com'
			};
			func(JSON.stringify(params)).then(response => {
				loader.hide();
        this.$toast.success(this.$t('support_email_sent'));
        this.question = '';
        this.email = '';
			}).catch(error => {
				loader.hide();
        this.$toast.error(this.$t('support_email_sent_failed') + '\n' + error.code + ', ' + error.message);
			});
    },
    onCaptchaVerified() {
      this.captchaVerified = true;
    },
    onCaptchaExpired() {
      this.captchaVerified = false;
    },
    onCaptchaError() {
      this.captchaVerified = false;
    }
  }
};
</script>
